<template>
	<v-card elevation="0" class="min-height-screen">
		<v-toolbar flat class="border-b-2 border--secondary px-0 py-3 !h-85px">
			<v-toolbar-title><strong>All products</strong></v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn
				icon
				@click="
					showSearchBar = !showSearchBar
					searchTerm = ''
				"
			>
				<v-icon>mdi-magnify</v-icon>
			</v-btn>
		</v-toolbar>

		<transition name="fade">
			<v-form v-if="showSearchBar" @submit.prevent="">
				<v-text-field
					outlined
					hide-details
					v-model.trim="searchTerm"
					placeholder="Search products..."
				/>
			</v-form>
		</transition>

		<section>
			<keep-alive>
				<component :is="listComponent" v-bind="attrs" />
			</keep-alive>
		</section>
	</v-card>
</template>

<script>
import ProductsList from '@/components/ProductsList.vue'
import SearchProductsList from '@/components/SearchProductsList.vue'

export default {
	name: 'Products',
	components: { ProductsList, SearchProductsList },
	data() {
		return {
			showSearchBar: false,
			searchTerm: '',
		}
	},
	computed: {
		listComponent() {
			if (this.searchTerm) return 'search-products-list'
			return 'products-list'
		},
		attrs() {
			if (this.listComponent === 'search-products-list') {
				return {
					value: this.searchTerm,
				}
			}

			return {}
		},
	},
}
</script>
