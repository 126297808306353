<template>
	<div class="">
		<!-- products loading spinner -->
		<div
			v-if="isSearchProductsLoading"
			class="d-flex align-center justify-center w-full h-full"
			style="height: 400px"
		>
			<loader />
		</div>
		<div v-else>
			<!-- show products -->
			<div v-if="searchProducts && searchProducts.length > 0">
				<a
					v-for="product in searchProducts"
					:key="product.id"
					:href="`${baseUrl}/product/${product.id}?token=${authToken}`"
					class="text-decoration-none"
					ref="searchProductsListRef"
				>
					<product-card :product="product" />
				</a>

				<!-- show loading more spinner -->
				<transition name="fade" mode="out-in">
					<div
						v-if="isMoreSearchProductsLoading"
						class="d-flex align-center justify-center w-full h-full mt-6"
					>
						<loader />
					</div>
				</transition>
			</div>
			<!-- no products -->
			<div
				v-else-if="hasNoSearchProducts"
				class="d-flex align-center justify-center w-full h-full mt-6"
			>
				<h3>No products found</h3>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
import debounceFn from 'debounce-fn'
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import ProductCard from '@/components/ProductCard.vue'
import { getAllProductsByCategory } from '@/services/products'
import { SUCCESS, NOT_FOUND } from '@/constants/status-code'

const PAGE_SIZE = 10

export default {
	name: 'SearchProductList',
	mixins: [AuthMixin],
	components: { Loader, ProductCard },
	props: {
		value: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			searchTerm: this.value,
			isSearchProductsLoading: false,
			isMoreSearchProductsLoading: false,
			searchProducts: [],
			pageNo: 1,
			observer: null,
			currentProductListLastElementRef: null,
		}
	},
	mounted() {
		this.$watch(
			() => this.searchTerm,
			debounceFn(
				() => {
					this.pageNo = 1
					this.fetchSearchProducts()
				},
				{ wait: 500 }
			)
		)
	},
	watch: {
		value(newVal) {
			this.searchTerm = newVal
		},
		searchTerm(newValue) {
			this.$emit('input', newValue)
		},
	},
	computed: {
		hasNoSearchProducts() {
			return (
				!this.isSearchProductsLoading &&
				Array.isArray(this.searchProducts) &&
				!this.searchProducts.length
			)
		},
	},
	methods: {
		isIntersecting(entries) {
			entries.forEach(({ isIntersecting }) => {
				if (isIntersecting) {
					this.pageNo += this.pageNo
					this.fetchSearchProducts({ fetchNext: true })
				}
			})
		},
		addOrRemoveObeserver(onlyRemoveObserver = false) {
			if (
				// eslint-disable-next-line operator-linebreak
				this.observer instanceof IntersectionObserver &&
				this.currentProductListLastElementRef
			) {
				this.observer.unobserve(this.currentProductListLastElementRef)
				this.observer = null
			}
			if (onlyRemoveObserver) return

			// eslint-disable-next-line operator-linebreak
			this.currentProductListLastElementRef =
				this.$refs.searchProductsListRef[this.$refs.searchProductsListRef.length - 1]

			this.observer = new IntersectionObserver(this.isIntersecting)
			this.observer.observe(this.currentProductListLastElementRef)
		},
		async fetchSearchProducts(args = {}) {
			const { fetchNext } = args
			const params = {
				categoryId: 0,
				searchText: this.searchTerm,
				pageNo: this.pageNo,
				pageSize: PAGE_SIZE,
			}

			if (fetchNext) {
				this.isMoreSearchProductsLoading = true
			} else {
				this.isSearchProductsLoading = true
			}

			try {
				const res = await getAllProductsByCategory(this.authToken, { params })
				const { data, statusCode } = res.data

				if (statusCode === NOT_FOUND) {
					this.searchProducts = []
				}

				if (statusCode === SUCCESS) {
					const { totalPage, result: products } = data
					const hasNoMoreProducts = products.length > 0
					const removeObserver = !hasNoMoreProducts

					this.searchProducts = fetchNext ? [...this.searchProducts, ...products] : products
					// dont add observer if products has only 1 or less page
					if (totalPage > 1) {
						this.$nextTick().then(() => {
							this.addOrRemoveObeserver(removeObserver)
						})
					}
				}
			} catch (e) {
				console.log(e, 'error')
			}

			if (fetchNext) {
				this.isMoreSearchProductsLoading = false
			} else {
				this.isSearchProductsLoading = false
			}
		},
	},
}
</script>
