<template>
	<div
		v-if="isProductCategoryLoading"
		class="d-flex align-center justify-center w-full h-full"
		style="height: 400px"
	>
		<loader />
	</div>
	<div v-else>
		<!-- show product categories -->
		<v-tabs v-model="tab" show-arrows>
			<v-tabs-slider color="primary"></v-tabs-slider>

			<v-tab v-for="category in productCategories" :key="category.id">
				<strong>{{ category.name }}</strong>
			</v-tab>
		</v-tabs>
		<!-- tab items -->
		<v-tabs-items v-model="tab">
			<v-tab-item v-for="category in productCategories" :key="category.id" class="py-4">
				<div class="">
					<!-- products loading spinner -->
					<div
						v-if="isProductsLoading"
						class="d-flex align-center justify-center w-full h-full"
						style="height: 400px"
					>
						<loader />
					</div>
					<div v-else>
						<!-- show products -->
						<div v-if="products && products.length > 0">
							<a
								v-for="product in products"
								:key="product.id"
								:href="`${baseUrl}/product/${product.id}?token=${authToken}`"
								class="text-decoration-none"
								:ref="`${category.name.toLowerCase()}-productsListRef`"
							>
								<product-card :product="product" />
							</a>

							<!-- show loading more spinner -->
							<transition name="fade" mode="out-in">
								<div
									v-if="isMoreProductsLoading"
									class="d-flex align-center justify-center w-full h-full mt-6"
								>
									<loader />
								</div>
							</transition>
						</div>
						<!-- no products -->
						<div
							v-else-if="hasNoProducts"
							class="d-flex align-center justify-center w-full h-full mt-6"
						>
							<h3>No products found</h3>
						</div>
					</div>
				</div>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import ProductCard from '@/components/ProductCard.vue'
import { getProductCategories } from '@/services/product-category'
import { getAllProductsByCategory } from '@/services/products'
import { SUCCESS, NOT_FOUND } from '@/constants/status-code'

const PAGE_SIZE = 10

export default {
	name: 'ProductsList',
	mixins: [AuthMixin],
	components: { Loader, ProductCard },
	data() {
		return {
			isProductCategoryLoading: false,
			isProductsLoading: false,
			isMoreProductsLoading: false,
			tab: null,
			productCategories: [],
			products: [],
			pageNo: 1,
			observer: null,
			currentProductListRef: null,
			currentProductListLastElementRef: null,
		}
	},
	created() {
		this.fetchProductCategories()
	},
	watch: {
		tab() {
			this.pageNo = 1
			this.fetchProductsOfCategory()
		},
	},
	computed: {
		hasNoProducts() {
			return !this.isProductsLoading && this.products && this.products.length <= 0
		},
	},
	methods: {
		isIntersecting(entries) {
			entries.forEach(({ isIntersecting }) => {
				if (isIntersecting) {
					this.pageNo += this.pageNo
					this.fetchProductsOfCategory({ fetchNext: true })
				}
			})
		},
		addOrRemoveObeserver(onlyRemoveObserver = false) {
			const category = this.productCategories[this.tab]
			this.currentProductListRef = this.$refs[`${category.name.toLowerCase()}-productsListRef`]

			if (
				// eslint-disable-next-line operator-linebreak
				this.observer instanceof IntersectionObserver &&
				this.currentProductListLastElementRef
			) {
				this.observer.unobserve(this.currentProductListLastElementRef)
				this.observer = null
			}
			if (onlyRemoveObserver) return

			// eslint-disable-next-line operator-linebreak
			this.currentProductListLastElementRef =
				this.currentProductListRef[this.currentProductListRef.length - 1]

			this.observer = new IntersectionObserver(this.isIntersecting)
			this.observer.observe(this.currentProductListLastElementRef)
		},
		async fetchProductCategories() {
			this.isProductCategoryLoading = true
			try {
				const res = await getProductCategories(this.authToken)
				const { data, statusCode } = res.data

				if (statusCode !== SUCCESS) throw new Error('Error getting product categories')

				this.productCategories = data
				this.tab = 0
			} catch (e) {
				console.log(e, 'error')
			}
			this.isProductCategoryLoading = false
		},
		async fetchProductsOfCategory(args = {}) {
			const { fetchNext } = args
			const category = this.productCategories[this.tab]
			const params = {
				categoryId: category.id,
				searchText: '',
				pageNo: this.pageNo,
				pageSize: PAGE_SIZE,
			}

			if (fetchNext) {
				this.isMoreProductsLoading = true
			} else {
				this.isProductsLoading = true
			}

			try {
				const res = await getAllProductsByCategory(this.authToken, { params })
				const { data, statusCode } = res.data

				if (statusCode === NOT_FOUND) {
					this.products = []
				}

				if (statusCode === SUCCESS) {
					const { totalPage, result: products } = data
					const hasNoMoreProducts = products.length > 0
					const removeObserver = !hasNoMoreProducts

					this.products = fetchNext ? [...this.products, ...products] : products
					// dont add observer if products has only 1 or less page
					if (totalPage > 1) {
						this.$nextTick().then(() => {
							this.addOrRemoveObeserver(removeObserver)
						})
					}
				}
			} catch (e) {
				console.log(e, 'error')
			}

			if (fetchNext) {
				this.isMoreProductsLoading = false
			} else {
				this.isProductsLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
:deep(.v-slide-group__content.v-tabs-bar__content) {
	justify-content: space-evenly;
}
:deep(.v-tabs:not(.v-tabs--vertical) .v-tab) {
	flex-grow: 1;
}
</style>
